/**
 * Direttiva che costruisce una card rappresentante l'oggetto
 */
import { Item, User, SenecaResponse, UserGroup } from "atfcore-commonclasses";

export interface ICardDirectiveScope extends ng.IScope {
	card: any;
	goToItemDetail: Function;
	itemType: any;
	hourValue: any;
	techCompetences: any;
	softCompetences: any;
	hasSuggested: boolean;
	globalApplicationData: any;
	libraryApplicationData: any;
	suggestedPerson: any;
	isLearningPlan: boolean;
	isProject: boolean;
	isSection: boolean;
	page: any;
	item: any;
	percentageItem: string;
	suggestedFromAdmin: boolean;
	forceToggleIndicator: boolean;
	defaultOnSuggestion: boolean;
	isItemAlreadyCertified: boolean;
	isMandatory: boolean;
	mandatory: number;
	changeMandatory: Function;
	propedeuticReferenceId: string;
	isPropedeutic: boolean;
	hasPrerequisites: boolean;
	changePrerequisites: Function;
	propedeuticReferenceTitle: Function;
	propedeuticTitle: string;
	setPropedeuticOffCb: Function;
	prerequisites: any;
	propedeuticReferenceIds: Array<string>;
	checkPropedeuticReferenceIds: Function;
	referenceId: string;
	isItemDisabled: any;
	combinedCompetences: any;
	cardCover: any;
	currentItemType: any;
	currentItem: any;
	isScorm: boolean;
	isConsumed: boolean;
	isNotDisabled: boolean;
	adminMode: boolean;
	canDeleteItem: boolean;
	canEditItem: boolean;
	deleteItem: Function;
	deleteLearningPlan: Function;
	deleteProject: Function;
	deleteAtomicItem: Function;
	suggestItem: Function;
	inviteToUserOrGroup: Function;
	canSuggestItem: boolean;
	canInviteToUserOrGroup: boolean;
	childNumbers: number;
	isItemPublic: boolean;
	clusterTitle: any;
	clusterClass: any;
	goToEditItem: Function;
	convertTagIntoClassName: Function;
	isCheckboxToAddItemVisible: boolean;
	isCheckboxToManageItemVisible: boolean;
	isCheckBoxVisible: boolean;
	isLearningPlanEditingMode: any;
	isMyItem: boolean;
	footerClass: any;
	getFooterClass: Function;
	containerClass: string;
	opacityLayerClass: string;
	clusterInformations: boolean;
	isItemContainer: boolean;
	clusterTitleClass: string;
	itemChildsTitle: string;
	bgDisabledClass: string;
	textDisabledClass: string;
	textWhiteDisabledClass: string;
	isRequired: boolean;
	isNotRequired: boolean;
	showPrompters: boolean;
	isCompleted: boolean;
	iconType: any;
	subIconType: string;
	showHourValue: boolean;
	isHourValueValorised: boolean;
	percentageDescription: string;
	dontShowCompetences: boolean;
	isDetailBtnVisible: boolean;
	checkIfRequired: Function;
	checkIfNotRequired: Function;
	isEditSectionOrPlayList: boolean;
	showPropedeuticalItem: Function;
	showPropedeuticalItemToThis: Function;
	topBgLabel: string;
	subtype: any;
	isTenantOwner: boolean;
}
angular.module('app').directive("card", ($window, $state, LibraryApplicationData, GlobalApplicationData, moment, $stateParams, $sessionStorage, $mdDialog, blockUI, LearningPlanService, toaster, $translate, $rootScope, ProjectService, VisibilityService, $timeout, ItemDetailService, GroupService, $mdSidenav) => {
	return {
		restrict: 'E',
		transclude: true,
		scope: {
			page: '@',
			card: '=',
			isLearningPlanEditingMode: '@',
			mandatory: '=', // obbligatorietà
			propedeuticReferenceId: '=', // id dell'oggetto propedeutico per quello attuale
			propedeuticReferenceTitle: '&', // Titolo della referenza propedeutica
			forceToggleIndicator: '=', // Toggle indicatore di default (open, consumed, percentage...) / suggeritore
			openItemDetail: '&',
			suggestedPersonExists: '&',
			toggleSuggestedPerson: '&',
			itemChildsExists: '&',
			toggleItemChilds: '&',
			managePrerequisites: '=',
			propedeuticReferenceIds: '=', // lista dei propedeuticReferenceIds di ogni itemChilds 
			showPropedeuticalItemToThis: '&', // apre una modale per indicare quale oggetto è propedeutico per sbloccare quello corrente
			currentItemType: '=',
			currentItem: '=',
			statusType: '=', // stato dell'oggetto prestabile
			isNotDisabled: '='
		},
		link: link,
		templateUrl: 'app/shared/card/card.html'
	};
	function link($scope: ICardDirectiveScope, element: JQuery, attrs: ng.IAttributes) {
		// Item passato alla Direttiva. Se esiste il referenceId significa che si tratta di un itemChilds
		if (!$scope.card.referenceId || !$scope.card.childObject) {
			$scope.item = $scope.card;
		} else {
			// Prima di salvare il childObject, recupero l'referenceId affinchè, in seguito, possa verifica se tale oggetto è propedeutico per qualche altro
			$scope.referenceId = $scope.card.referenceId;
			$scope.item = $scope.card.childObject;
		}

		// Verifica se l'utente loggato è owner del tenant a cui appartiene l'Item
		if (GlobalApplicationData && GlobalApplicationData.jwtPayload && GlobalApplicationData.jwtPayload.user && GlobalApplicationData.jwtPayload.user.userTenant && GlobalApplicationData.jwtPayload.user.userTenant.adminTenants && GlobalApplicationData.jwtPayload.user.userTenant.adminTenants.length) {
			// Recupero il tenant dell'item
			let adminTenantOfItem: any = null;
			if ($scope.item && $scope.item.itemTenants && $scope.item.itemTenants.length) {
				for (let i = 0, tenantsLength = $scope.item.itemTenants.length; i < tenantsLength; i++) {
					let currentTenant = $scope.item.itemTenants[i];
					if (currentTenant.owner) {
						adminTenantOfItem = currentTenant.tenant;
						break;
					}
				}
			}
			if (adminTenantOfItem) {
				for (let i = 0, tenantsLength = GlobalApplicationData.jwtPayload.user.userTenant.adminTenants.length; i < tenantsLength; i++) {
					let currentAdminUserTenant = GlobalApplicationData.jwtPayload.user.userTenant.adminTenants[i];
					if (currentAdminUserTenant === adminTenantOfItem) {
						$scope.isTenantOwner = true;
						break;
					}
				}
			}
		}

		// Classi CSS che andranno nel contenitore dell'intera card
		$scope.containerClass = '';

		// Classe CSS dell'opacity-layer (aggiunta solo se l'item è disabilitato)
		$scope.opacityLayerClass = '';

		// Titolo dell'intestazione riguardante gli oggetti contenuti
		$scope.itemChildsTitle = null;

		// Verifica se è un oggetto contenitore
		$scope.isItemContainer = false;

		// Classe CSS da aggiungere al titolo
		$scope.clusterTitleClass = '';

		// Classe CSS aggiunta allo sfondo quando l'Item è disabilitato
		$scope.bgDisabledClass = '';

		// Classe CSS aggiunta al testo quando l'Item è disabilitato
		$scope.textDisabledClass = '';

		// Verifica se far vedere i suggeritori
		$scope.showPrompters = false;

		// Verifica se c'è almeno o l'ora o i minuti nel monte ore
		$scope.isHourValueValorised = false;

		// Verifica se è completato
		$scope.isCompleted = false;

		// Descrizione sotto la percentuale
		$scope.percentageDescription = null;

		// Icona per la tipologia di oggetto
		$scope.iconType = null;

		// Verifica se nascondere le competenze
		$scope.dontShowCompetences = false;

		// Verifica se mostrare il monte ore
		$scope.showHourValue = false;

		// Scritta sotto l'icona della tipologia di oggetto
		$scope.subIconType = null;

		// Classe CSS aggiunta al testo quando l'Item è disabilitato
		$scope.textWhiteDisabledClass = '';

		// Verifica se sono nella parte amministrativa della Library
		$scope.adminMode = $sessionStorage.adminMode;

		// Verifica il numero di itemChilds (così da sapere quanti oggetti ha un LP o quante sezioni ha un progetto)
		$scope.childNumbers = 0;

		// Verifica se l'utente può eliminare l'item
		$scope.canDeleteItem = false;

		// Verifica se l'utente può andare in modifica dell'item
		$scope.canEditItem = false;

		// Verifico se è un oggetto scorm
		$scope.isScorm = $scope.item && $scope.item.itemType && ($scope.item.itemType == LibraryApplicationData.constants.SCORM_FREE || $scope.item.itemType == LibraryApplicationData.constants.SCORM_INVITE) ? true : false;

		// Salvo l'obbligatorietà
		$scope.isMandatory = !!$scope.mandatory;

		// Immagine di copertina
		$scope.cardCover = null;

		// Controllo se non è obbligatorio
		$scope.checkIfNotRequired = () => {
			if ($scope.isPropedeutic || ($scope.mandatory == 1 && $scope.isPropedeutic)) {
				$scope.isNotRequired = true;
			} else {
				$scope.isNotRequired = false;
			}
		}

		// Verifico se questo oggetto è propedeutico per qualche altro oggetto
		$scope.isPropedeutic = false;
		$scope.checkPropedeuticReferenceIds = () => {
			if ($scope.propedeuticReferenceIds && $scope.propedeuticReferenceIds.length) {
				for (let m = 0; m < $scope.propedeuticReferenceIds.length; m++) {
					if ($scope.propedeuticReferenceIds[m] == $scope.referenceId) {
						$scope.isPropedeutic = true;
						break;
					} else {
						$scope.isPropedeutic = false;
					}
				}
			} else {
				$scope.isPropedeutic = false;
			}
			$scope.checkIfNotRequired();
		}
		if ($scope.propedeuticReferenceIds) {
			$scope.checkPropedeuticReferenceIds();
		}

		$scope.checkIfNotRequired();

		// Verifica se sono nella pagina di edit di una sezione o di una playlist lato admin
		if ($scope.page == 'editSection' || $scope.page == 'editLearningPlanAdmin') {
			$scope.isEditSectionOrPlayList = true;
		} else {
			$scope.isEditSectionOrPlayList = false;
		}

		// Verifica se mostrare la checkbox per selezionare l'elemento corrente e aggiungerlo ad una Playlist o una sezione di un Learning Plan
		$scope.isCheckboxToAddItemVisible = false;
		if (($scope.isLearningPlanEditingMode && $scope.page === 'addItemToCurrentLearningPlan') || $scope.page === 'addItemToCurrentSection') {
			$scope.isCheckboxToAddItemVisible = true;
		}

		// Verifica se mostrare la checkbox per selezionare e cambiare l'ordine
		$scope.isCheckboxToManageItemVisible = false;
		if (($scope.isLearningPlanEditingMode && $scope.page === 'editLearningPlan') || ($scope.isLearningPlanEditingMode && $scope.page === 'editLearningPlanAdmin') || ($scope.isLearningPlanEditingMode && $scope.page === 'editSection')) {
			$scope.isCheckboxToManageItemVisible = true;
		}

		if ($scope.isCheckboxToAddItemVisible || $scope.isCheckboxToManageItemVisible) {
			$scope.isCheckBoxVisible = true;
		} else {
			$scope.isCheckBoxVisible = false;
		}

		// Verifico se esiste un altro oggetto propedeutico per quello attuale
		$scope.prerequisites = {
			hasPrerequisites: $scope.propedeuticReferenceId ? true : false
		}

		// Callback che, se ho chiuso la modale prima di selezionare un oggetto a cui aggiugnere la propedeuiticità, spenge lo switch
		$scope.setPropedeuticOffCb = () => {
			$scope.prerequisites.hasPrerequisites = false;
		}

		// Se ho un item propedeutico a quello attuale, recupero il suo titolo
		if ($scope.prerequisites.hasPrerequisites) {
			$scope.propedeuticTitle = $scope.propedeuticReferenceTitle($scope.item);
		}

		// Quando cambia l'oggetto propedeutico di quello attuale, devo recuperare il suo titolo
		$scope.$watch('propedeuticReferenceId', (newValue, oldValue) => {
			if (newValue) {
				$scope.propedeuticTitle = $scope.propedeuticReferenceTitle($scope.item);
				$scope.checkPropedeuticReferenceIds();
			}
		}, true);

		// Quando cambia la lista degli oggetti propedeutici, devo aggiornare le etichette di obbligatorietà poichè l'item attuale potrebbe essere diventato
		// un item propedeutico a qualcos'altro
		$scope.$watch('propedeuticReferenceIds', (newValue, oldValue) => {
			if (newValue) {
				$scope.checkPropedeuticReferenceIds();
			}
		}, true);

		// Il mandatory a db è un numero (0 / 1), ma all'md-switch serve un boolean. Così devo gestire manualmente lo switch
		$scope.changeMandatory = () => {
			if ($scope.isMandatory == true) {
				$scope.mandatory = 0;
				$scope.isMandatory = false;
			} else {
				$scope.mandatory = 1;
				$scope.isMandatory = true;
			}

			$scope.checkIfRequired();
		}

		// Verifica se come prima cosa devo mostrare la persona (non ho altri dati disponibili)
		$scope.defaultOnSuggestion = true;

		// Elenco di tag delle competenze tecniche
		$scope.techCompetences = [];

		// Elenco di tag delle competenze soft
		$scope.softCompetences = [];

		// Mix con l'elenco alternato di competenze soft e tech
		$scope.combinedCompetences = [];

		// Verifica se è un learning Plan
		$scope.isLearningPlan = false;

		// Verifica se è un progetto
		$scope.isProject = false;

		// Verifica se l'item è stato completato
		$scope.isConsumed = false;

		// Verifica se è una sezione
		$scope.isSection = false;

		// Tipo dell'oggetto (video, audio, e-book...)
		$scope.itemType = null;

		// Sottotipo
		$scope.subtype = null;

		// Titolo del cluster aggiunto all'elemento
		$scope.clusterTitle = null;

		// Nome della classe CSS relativa al cluster aggiunto all'elemento
		$scope.clusterClass = null;

		// Verifica se c'è il titolo dei cluster e la corrispettiva classe CSS
		$scope.clusterInformations = false;

		// Nome della calsse css del footer
		$scope.footerClass = null;

		// Valore formativo
		$scope.hourValue = null;

		// Persone che hanno suggerito l'elemento
		$scope.suggestedPerson = [];

		// Suggerito dall'amminsitrazione
		$scope.suggestedFromAdmin = false;

		// Verifica se l'oggetto è pubblico
		$scope.isItemPublic = false;

		// Collegamento agli oggetti principali
		$scope.globalApplicationData = GlobalApplicationData;
		$scope.libraryApplicationData = LibraryApplicationData;

		// Cambio la classe del titolo in base alla sua lunghezza, così diminuendo il font-size evito che sfori
		if ($scope.item && $scope.item.title && $scope.item.title.length < 62) {
			$scope.item.titleClass = 'card-title-h3';
		} else if ($scope.item && $scope.item.title && $scope.item.title.length > 62) {
			$scope.item.titleClass = 'card-title-h2';
		}

		// Ricava il nome della classe CSS del cluster attraverso il suo nome
		$scope.convertTagIntoClassName = () => {
			if ($scope.clusterTitle) {
				let titleWithouthSpaces = $scope.clusterTitle.replace(/ /g, "-");
				$scope.clusterClass = titleWithouthSpaces.toLowerCase();
			}
		}

		// Verifico gli attributi dell'oggetto
		if ($scope.item) {
			// Verifico se c'è una visibilità pubblica
			if ($scope.item.hasPublicVisibility) {
				$scope.isItemPublic = true;
			}

			// Controllo se l'ho creato io
			if ($scope.item.creationUserId && ($scope.item.creationUserId == $scope.globalApplicationData.jwtPayload.user.userId)) {
				$scope.isMyItem = true;
				// Se sono proprietario dell'Item, valorizzo a true il campo isTenantOwner affinché nella toolbar is veda il pulsante di cancellazione/modifica
				$scope.isTenantOwner = true;
			}

			if ($scope.item.itemAttributes) {
				let itemAttributes = $scope.item.itemAttributes;
				for (let k = 0; k < itemAttributes.length; k++) {
					let currentAttribute = itemAttributes[k];
					// Verifico se è un Cluster
					if (!$scope.clusterTitle && currentAttribute.attributeType == LibraryApplicationData.constants.CLUSTERS && currentAttribute.crossReferenceObject) {
						$scope.clusterTitle = currentAttribute.crossReferenceObject.title;
						// Attraverso gli attributi del Tag, recupero la classe che avrà il cluster
						if (currentAttribute.crossReferenceObject.tagAttributes && currentAttribute.crossReferenceObject.tagAttributes.length) {
							for (let z = 0, tagAttributesLength = currentAttribute.crossReferenceObject.tagAttributes.length; z < tagAttributesLength; z++) {
								let currentTagAttribute = currentAttribute.crossReferenceObject.tagAttributes[z];
								if (currentTagAttribute.attributeType === LibraryApplicationData.constants.COLOR) {
									$scope.item.clusterBackground = "bg-" + currentTagAttribute.attributeValue;
								}
							}
						}
					}

					// Verifico il tipo di oggetto
					if (currentAttribute.attributeType == LibraryApplicationData.constants.OBJECT_TYPE) {
						$scope.itemType = currentAttribute.attributeValue;
						if (currentAttribute.attributeValue == LibraryApplicationData.constants.LEARNING_PLAN) {
							$scope.isLearningPlan = true;
							// Salvo il numero di oggetti in esso contenuti
							$scope.childNumbers = $scope.item.itemChilds.length;
						} else if (currentAttribute.attributeValue == LibraryApplicationData.constants.PROJECT) {
							$scope.isProject = true;
							// Salvo il numero di oggetti in esso contenuti
							$scope.childNumbers = $scope.item.itemChilds.length;
						} else if (currentAttribute.attributeValue == LibraryApplicationData.constants.SECTION) {
							$scope.isSection = true;
						}
					}

					// Verifico se ha il Sottotipo
					if (currentAttribute.attributeType == LibraryApplicationData.constants.OBJECT_TYPE_SPECIALIZATION) {
						$scope.subtype = currentAttribute.attributeValue;
					}

					// Verifico se c'è l'immagine di copertina
					if (currentAttribute.attributeType == LibraryApplicationData.constants.CARD_COVER.ITEM_TYPE) {
						$scope.cardCover = currentAttribute.attributeValue;
					}

					// Verifico se c'è il valore formativo
					if (currentAttribute.attributeType == LibraryApplicationData.constants.VALUE) {
						let duration = moment.duration(parseInt(currentAttribute.attributeValue), 'seconds');
						$scope.hourValue = {
							hours: duration.minutes(),
							minutes: duration.seconds()
						}
					}

					// Recupero i tag - se non c'è il crossReferenceObject significa che non è un vero tag, quindi lo salto
					if (currentAttribute.crossReferenceObject) {
						if (currentAttribute.attributeType == LibraryApplicationData.constants.TECH_COMPETENCES) {
							$scope.techCompetences.push(currentAttribute.crossReferenceObject.title);
						} else if (currentAttribute.attributeType == LibraryApplicationData.constants.SOFT_COMPETENCES) {
							$scope.softCompetences.push(currentAttribute.crossReferenceObject.title);
						}
					}
				}

				// Se non ho trovato il sottotipo, lo setto di default come la tipologia dell'oggetto
				if (!$scope.subtype) {
					if ($scope.itemType) {
						$scope.subtype = $scope.itemType;
					} else if (!$scope.itemType && $scope.isScorm) {
						$scope.subtype = LibraryApplicationData.constants.SCORM;
					}
				}

				// Se non c'è l'immagine della card ne metto una di default
				if (!$scope.cardCover) {
					$scope.cardCover = '../assets/img/img-card.jpg'
				}
			}

			// Elenco contenente un mix alternato di competenze tecniche e soft
			for (var i = 0, len = Math.max($scope.techCompetences.length, $scope.softCompetences.length); i < len; i++) {
				if (i < $scope.techCompetences.length) {
					$scope.combinedCompetences.push($scope.techCompetences[i]);
				}
				if (i < $scope.softCompetences.length) {
					$scope.combinedCompetences.push($scope.softCompetences[i]);
				}
			}

			// Se possiede la scormRegistration, prendo da qui la percentuale di avanzamento
			let hasScormRegistration: boolean = $scope.card && $scope.card.scormRegistration ? true : false;
			if (hasScormRegistration) {
				$scope.percentageItem = $scope.card.scormRegistration.score;
			}

			// Se possiede l'itemRegistration (quindi non è uno scorm ma è un oggetto Kaltura), prendo da qui la percentuale di avanzamento
			let hasItemRegistration: boolean = $scope.item && $scope.item.itemRegistration ? true : false;
			if (hasItemRegistration) {
				$scope.percentageItem = $scope.item.itemRegistration.currentPrecentage;
			}

			// Verifico gli engagement, cioè le azioni dell'utente sull'Item corrente
			if ($scope.item.engagements) {
				// Data dell'evento EVENT_ITEM_STARTED
				let dateEventItemStarted: any = null;
				// Data dell'evento EVENT_ITEM_CONSUMED
				let dateEventItemConsumed: any = null;
				// Percentuale di avanzamento, cioè quella fornita dall'EVENT_ITEM_STARTED
				let percentageEventItemStarted: any = null;

				// Inizio a scorrermi tutti gli engagement
				for (let d = 0; d < $scope.item.engagements.length; d++) {
					let currentEngagement = $scope.item.engagements[d];

					if (currentEngagement.moduleName == LibraryApplicationData.constants.ITEM) {
						// Attualmente, la percentuale di avanzamento (o percentuale degli oggetti) è data dalla percentuale più recente fra quella dell'item consumed e quella dell'item started. Quindi la più recente, va mostrata. Di conseguenza, devo recuperarle entrambe e, poi, confrontarle
						if (currentEngagement.eventName == LibraryApplicationData.constants.EVENT_ITEM_CONSUMED) {
							// Item concluso. Salvo la data in cui è stato creato tale engagement
							dateEventItemConsumed = currentEngagement.creationDate;
							// L'item è stato concluso
							$scope.isConsumed = true;
						}

						if (currentEngagement.eventName == LibraryApplicationData.constants.EVENT_ITEM_STARTED && currentEngagement.engagementDetails) {
							// Item iniziato. Salvo la data in cui è stato creato tale engagement e la percentuale
							dateEventItemStarted = currentEngagement.creationDate;
							for (let z = 0; z < currentEngagement.engagementDetails.length; z++) {
								let currentEngagementDetail = currentEngagement.engagementDetails[z];
								if (!hasItemRegistration && !hasScormRegistration && currentEngagementDetail.detailKey == LibraryApplicationData.constants.PERCENTAGE) {
									percentageEventItemStarted = currentEngagementDetail.detailValue;
									if (isNaN(percentageEventItemStarted)) {
										percentageEventItemStarted = "0";
									}
									break;
								}
							}
						}

						// Verifico se l'oggetto è già stato verificato
						if (currentEngagement.eventName == LibraryApplicationData.constants.EVENT_ITEM_CERTIFIED) {
							$scope.isItemAlreadyCertified = true;
						}
					}
				}

				// Se ho sia l'engagement STARTED che CONSUMED, devo recuperare il più recente
				if (!hasScormRegistration && !hasItemRegistration) {
					if (dateEventItemStarted && dateEventItemConsumed) {
						let startedTime = new Date(dateEventItemStarted).getTime();
						let consumedTime = new Date(dateEventItemConsumed).getTime();
						if (startedTime > consumedTime) {
							// E' più recente l'evento started, quindi visualizzo a front-end la sua percentuale
							$scope.percentageItem = percentageEventItemStarted;
						} else {
							// Altrimenti la setto io al 100%
							$scope.percentageItem = "100";
						}
					} else if (dateEventItemStarted && !dateEventItemConsumed) {
						$scope.percentageItem = percentageEventItemStarted;
					} else if (!dateEventItemStarted && dateEventItemConsumed) {
						$scope.percentageItem = "100";
					} else {
						$scope.percentageItem = "0";
					}
				}
			}

			if ($scope.item.itemVisibilities) {
				// Ordino le visibilities per data
				$scope.item.itemVisibilities.sort((a: any, b: any) => {
					if (a.creationDate > b.creationDate) {
						return -1;
					} else if (a.creationDate < b.creationDate) {
						return 1;
					} else {
						return 0;
					}
				});
				// Verifico se ci sono suggeriti (visibility = 'suggestion'), e se ci sono li aggiungo alla lista
				for (let j = 0; j < $scope.item.itemVisibilities.length; j++) {
					// Se è stato suggerito dall'amministrazione, ha la precedenza
					if ($scope.item.itemVisibilities[j].visibilityType == LibraryApplicationData.constants.SUGGESTION && $scope.item.itemVisibilities[j].fromAdmin == true) {
						$scope.suggestedFromAdmin = true;
						break;
					} else if ($scope.item.itemVisibilities[j].visibilityType == LibraryApplicationData.constants.SUGGESTION) {
						// Verifico se ho i permessi per visualizzarlo
						let groupFound: boolean = false;
						for (let z = 0; z < $scope.globalApplicationData.userGroups.length; z++) {
							if ($scope.globalApplicationData.userGroups[z].groupId == $scope.item.itemVisibilities[j].allowedGroupId) {
								groupFound = true;
								break;
							}
						}
						if (groupFound || ($scope.globalApplicationData.jwtPayload.user.userId == $scope.item.itemVisibilities[j].allowedUserId)) {
							$scope.hasSuggested = true;
							// Salvo chi me l'ha suggerito
							$scope.suggestedPerson.push($scope.item.itemVisibilities[j]);
						}
					}
				}

				// Se ho copie di suggerimenti multipli, li rimuovo
				if ($scope.suggestedPerson && $scope.suggestedPerson.length) {
					let filtederSuggestedList: Array<any> = [];
					var lookupObject: any = {};
					let i: any;

					for (i in $scope.suggestedPerson) {
						if ($scope.suggestedPerson[i] && $scope.suggestedPerson[i].userObject && $scope.suggestedPerson[i].userObject["userId"]) {
							lookupObject[$scope.suggestedPerson[i].userObject["userId"]] = $scope.suggestedPerson[i];
						}
					}

					for (i in lookupObject) {
						filtederSuggestedList.push(lookupObject[i]);
					}
					$scope.suggestedPerson = filtederSuggestedList;
				}
			}

			// Posso cancellare o modificare un Item solo se sono admin oppure se sono un utente e il Learning Plan l'ho creato io
			if ($scope.isTenantOwner && ($scope.adminMode || ($scope.isLearningPlan && $scope.item.creationUserId && $scope.item.creationUserId == $scope.globalApplicationData.jwtPayload.user.userId) && $scope.page != 'itemDetail' && $scope.page != 'sectionDetail')) {
				$scope.canDeleteItem = true;
				$scope.canEditItem = true;
			}
		}

		// Di default l'item è abilitato
		$scope.isItemDisabled = false;

		if ($scope.propedeuticReferenceId && !$scope.isNotDisabled && $scope.page != 'editLearningPlanAdmin' && $scope.page != 'editSection' && $scope.page != 'editProject') {
			$scope.isItemDisabled = true;
		}

		// Calcola la classe css che dovrà avere il footer
		$scope.getFooterClass = () => {
			if ($scope.isItemDisabled) {
				$scope.footerClass = 'bg-disable';
			} else if (!$scope.isItemDisabled && $scope.clusterClass && ($scope.isProject || $scope.isLearningPlan || $scope.isSection)) {
				$scope.footerClass = $scope.clusterClass;
			} else {
				$scope.footerClass = 'bg-footer-card';
			}
		}

		$scope.getFooterClass();

		// Porta alla pagina di dettaglio dell'oggetto selezionato
		$scope.goToItemDetail = (itemId: string) => {
			// Innanzitutto, se è aperta la sidenav dei più votati (quindi ho premuto sull'"Accedi" di un elemento più votato), devo chiuderla
			if ($mdSidenav('topVotedItemsSidenav') && $mdSidenav('topVotedItemsSidenav').isOpen()) {
				$mdSidenav('topVotedItemsSidenav').toggle();
			}
			// Innanzitutto, se è aperta la sidenav dei correlati (quindi ho premuto sull'"Accedi" di un elemento correlato), devo chiuderla
			if ($mdSidenav('suggestedItemsSidenav') && $mdSidenav('suggestedItemsSidenav').isOpen()) {
				$mdSidenav('suggestedItemsSidenav').toggle();
			}

			// Se l'id passato alla funzione è diverso dall'id dell'oggetto Item significa che sto aprendo
			// la card di un elemento suggerito (es Learning Plan)
			if (itemId != $scope.item.itemId) {
				if ($scope.itemType == $scope.libraryApplicationData.constants.LEARNING_PLAN) {
					$state.go("app.libraryApp.itemDetail", { itemId: $scope.item.itemId });
				} else if ($scope.currentItemType == $scope.libraryApplicationData.constants.SECTION) {
					$state.go("app.libraryApp.itemDetailSec", { projectId: $stateParams.projectId, sectionId: $stateParams.itemId, itemId: $scope.item.itemId });
				} else if ($scope.currentItemType == $scope.libraryApplicationData.constants.LEARNING_PLAN) {
					$state.go("app.libraryApp.itemDetailLp", { lpId: $stateParams.itemId, itemId: $scope.item.itemId });
				} else {
					$state.go("app.libraryApp.itemDetail", { itemId: $scope.item.itemId });
				}
			} else {
				// Se è un progetto, vado nel dettaglio del progetto
				if ($scope.itemType == $scope.libraryApplicationData.constants.PROJECT) {
					$state.go("app.libraryApp.projectDetail", { itemId: $scope.item.itemId });
				} else if ($scope.currentItemType == $scope.libraryApplicationData.constants.LEARNING_PLAN) {
					$state.go("app.libraryApp.itemDetail", { itemId: $scope.item.itemId });
				} else {
					$state.go("app.libraryApp.itemDetail", { itemId: $scope.item.itemId });
				}
			}
		}

		// Calcolo le classi che andranno aggiunte al contenitore dell'intera card
		if ($scope.isProject || $scope.isLearningPlan || $scope.isSection) {
			// Salvo il fatto che è un oggetto contenitore
			$scope.isItemContainer = true;
			$scope.containerClass += ' bg-card-content';

			// Imposto il colore della fascia in alto (rossa per le Playlist, verde per i Progetti)
			if ($scope.isProject) {
				$scope.topBgLabel = 'top-label-bg-card';
			} else if ($scope.isLearningPlan || $scope.isSection) {
				$scope.topBgLabel = 'bg-primary-1';
			}
		} else if (!$scope.isProject && !$scope.isLearningPlan && !$scope.isSection) {
			$scope.containerClass += ' bg-white';
		}

		if (!$scope.isItemDisabled) {
			$scope.containerClass += ' cursor-default';
		} else {
			$scope.containerClass += ' cursor-pointer';
			$scope.opacityLayerClass = 'opacity-layer';
			$scope.bgDisabledClass = 'bg-disable';
			$scope.textDisabledClass = 'text-disable';
			$scope.textWhiteDisabledClass = 'text-white';
		}

		// Controllo se l'oggetto è obbligatorio
		$scope.checkIfRequired = () => {
			if ($scope.mandatory == 1 || ($scope.mandatory == 1 && $scope.isPropedeutic)) {
				$scope.isRequired = true;
			} else {
				$scope.isRequired = false;
			}
		}

		$scope.checkIfRequired();

		// Verifico se far vedere il pulsante per andare nel dettaglio dell'oggetto
		if ($scope.isItemDisabled || $scope.isLearningPlanEditingMode) {
			$scope.isDetailBtnVisible = true;
		} else {
			$scope.isDetailBtnVisible = false;
		}

		// Verifico se far vedere i suggeritori
		if ($scope.suggestedPerson && $scope.suggestedPerson.length && !$scope.adminMode) {
			$scope.showPrompters = true;
		} else if (!$scope.suggestedPerson || !$scope.suggestedPerson.length || $scope.adminMode) {
			$scope.showPrompters = false;
		}

		// Verifico se ho le informazioni per il cluster
		if ($scope.clusterTitle) {
			$scope.clusterInformations = true;
		}

		// Verifico se è completato
		if ($scope.isConsumed && !$scope.isItemAlreadyCertified) {
			$scope.isCompleted = true;
		}

		// Traduco il testo sotto la percentuale
		if ($scope.isItemContainer) {
			$scope.percentageDescription = $translate.instant('card.types.ADVANCEMENT_OF_ITEMS');
		} else {
			$scope.percentageDescription = $translate.instant('card.types.ADVANCEMENT');
		}

		// Verifico la tipologia di oggetto per aggiungere l'icona e la traduzione
		if ($scope.subtype == LibraryApplicationData.constants.MAGAZINE) {
			$scope.iconType = "import-contacts";
			if ($scope.isItemDisabled) {
				$scope.iconType += " text-disable";
			}
			$scope.subIconType = $translate.instant('card.types.MAGAZINE');
		} else if ($scope.subtype == LibraryApplicationData.constants.DVD) {
			$scope.iconType = "album";
			if ($scope.isItemDisabled) {
				$scope.iconType += " text-disable";
			}
			$scope.subIconType = $translate.instant('card.types.DVD');
		} else if ($scope.subtype == LibraryApplicationData.constants.BOOK) {
			$scope.iconType = "library-books";
			if ($scope.isItemDisabled) {
				$scope.iconType += " text-disable";
			}
			$scope.subIconType = $translate.instant('card.types.BOOK');
		} else if ($scope.isLearningPlan) {
			$scope.iconType = "book";
			if ($scope.isItemDisabled) {
				$scope.iconType += " text-disable";
			}
			$scope.subIconType = $translate.instant('card.types.PLAYLIST');
		} else if ($scope.isProject) {
			$scope.iconType = "collections-bookmark";
			if ($scope.isItemDisabled) {
				$scope.iconType += " text-disable";
			}
			$scope.subIconType = $translate.instant('card.types.PROJECT');
		} else if ($scope.isSection) {
			$scope.iconType = "collections-bookmark";
			if ($scope.isItemDisabled) {
				$scope.iconType += " text-disable";
			}
			$scope.subIconType = $translate.instant('card.types.SECTION');
		} else if ($scope.subtype == LibraryApplicationData.constants.PODCAST) {
			$scope.iconType = "library-music";
			if ($scope.isItemDisabled) {
				$scope.iconType += " text-disable";
			}
			$scope.subIconType = $translate.instant('card.types.PODCAST');
		} else if ($scope.subtype == LibraryApplicationData.constants.GRAPH) {
			$scope.iconType = "featured-play-list";
			if ($scope.isItemDisabled) {
				$scope.iconType += " text-disable";
			}
			$scope.subIconType = $translate.instant('card.types.GRAPH');
		} else if ($scope.subtype == LibraryApplicationData.constants.DOCUMENT) {
			$scope.iconType = "description";
			if ($scope.isItemDisabled) {
				$scope.iconType += " text-disable";
			}
			$scope.subIconType = $translate.instant('card.types.DOCUMENT');
		} else if ($scope.subtype == LibraryApplicationData.constants.EBOOK) {
			$scope.iconType = "phone-android";
			if ($scope.isItemDisabled) {
				$scope.iconType += " text-disable";
			}
			$scope.subIconType = $translate.instant('card.types.EBOOK');
		} else if ($scope.isScorm && ($scope.subtype == LibraryApplicationData.constants.ELEARNING || $scope.subtype == LibraryApplicationData.constants.SCORM)) {
			$scope.iconType = "dvr";
			if ($scope.isItemDisabled) {
				$scope.iconType += " text-disable";
			}
			$scope.subIconType = $translate.instant('card.types.ELEARNING');
		} else if ($scope.subtype == LibraryApplicationData.constants.VIDEO) {
			$scope.iconType = "video-library";
			if ($scope.isItemDisabled) {
				$scope.iconType += " text-disable";
			}
			$scope.subIconType = $translate.instant('card.types.VIDEO');
		} else if ($scope.subtype == LibraryApplicationData.constants.IMAGE) {
			$scope.iconType = "insert-photo";
			if ($scope.isItemDisabled) {
				$scope.iconType += " text-disable";
			}
			$scope.subIconType = $translate.instant('card.types.IMAGE');
		}

		// Verifica se c'è o l'ora o i minuti nel monte ore
		if ($scope.hourValue && ($scope.hourValue.hours != 0 || $scope.hourValue.minutes != 0)) {
			$scope.isHourValueValorised = true;
		}

		// Verifico se mostrare il monte ore
		if ($scope.adminMode || $scope.isHourValueValorised) {
			$scope.showHourValue = true;
		}

		// Verifico se nascondere le competenze
		if ($scope.adminMode && (!$scope.combinedCompetences || $scope.combinedCompetences.length == 0)) {
			$scope.dontShowCompetences = true;
		}

		if ($scope.clusterTitle) {
			$scope.clusterTitleClass = 'label-counter-playlist-margin';
		} else {
			$scope.clusterTitleClass = 'no-margin';
		}

		// Traduco e aggiungo il titolo nell'intestazione relativa agli oggetti contenuti
		if (!$scope.isProject && $scope.childNumbers == 1) {
			$scope.itemChildsTitle = $translate.instant('card.OBJECT');
		} else if (!$scope.isProject && $scope.childNumbers != 1) {
			$scope.itemChildsTitle = $translate.instant('card.OBJECTS');
		} else if ($scope.isProject && $scope.childNumbers == 1) {
			$scope.itemChildsTitle = $translate.instant('card.SECTION');
		} else if ($scope.isProject && $scope.childNumbers != 1) {
			$scope.itemChildsTitle = $translate.instant('card.SECTIONS');
		}

		// Verifica se l'utente può suggerire l'elemento
		if (!$scope.adminMode && !$scope.isSection && !$scope.isProject && $scope.page != 'itemDetail' && $scope.page != 'sectionDetail') {
			$scope.canSuggestItem = true;
		} else {
			$scope.canSuggestItem = false;
		}

		// Verifica se mostrare il pulsante per invitare un gruppo o un utente a visualizzare un oggetto
		if ($scope.adminMode && $scope.page != 'itemDetail' && $scope.page != 'sectionDetail') {
			$scope.canInviteToUserOrGroup = true;
		} else {
			$scope.canInviteToUserOrGroup = false;
		}

		// Mostra l'oggetto propedeutico
		$scope.showPropedeuticalItem = () => {
			if ($scope.isItemDisabled) {
				$scope.showPropedeuticalItemToThis($scope.card);
			}
		}

		// Porta alla pagina di modifica di un Item
		$scope.goToEditItem = () => {
			if ($scope.item && $scope.item.itemId) {
				if ($scope.adminMode && $scope.isLearningPlan) {
					$state.go('app.libraryApp.editLearningPlanAdmin', { itemId: $scope.item.itemId });
				} else if (!$scope.adminMode && $scope.isLearningPlan) {
					$state.go('app.libraryApp.editLearningPlan', { itemId: $scope.item.itemId });
				} else if ($scope.adminMode && $scope.isProject) {
					$state.go('app.libraryApp.editProject', { itemId: $scope.item.itemId });
				} else if ($scope.adminMode && !$scope.isLearningPlan && !$scope.isProject) {
					$state.go('app.libraryApp.editItem', { itemId: $scope.item.itemId });
				}
			}
		}

		// In base alla tipologia dell'oggetto, apro la rispettava modale per cancellarlo
		$scope.deleteItem = (event: any) => {
			if ($scope.item && $scope.item.itemId) {
				if ($scope.isLearningPlan) {
					$scope.deleteLearningPlan(event);
				} else if ($scope.isProject && $scope.adminMode) {
					$scope.deleteProject(event);
				} else if ($scope.adminMode && !$scope.isProject && !$scope.isLearningPlan && !$scope.isSection) {
					$scope.deleteAtomicItem(event);
				}
			}
		}

		// Apre una modale che chiede conferma sulla rimozione della card
		$scope.deleteLearningPlan = (event: any) => {
			$mdDialog.show({
				controller: this.DeleteLearningPlanFromCardController,
				templateUrl: 'deleteLearningPlanFromCard.html',
				parent: angular.element(document.body),
				targetEvent: event,
				clickOutsideToClose: false
			})
				.then(() => {
					// Blocco la schermata
					blockUI.start();

					// Rimuovo l'oggetto
					LearningPlanService.deleteLearningPlan.query({
						itemId: $scope.item.itemId,
						removeAtomicItemsToo: false
					}).$promise
						.then((data: SenecaResponse<Item>) => {
							// Se c'è segnalo l'errore
							if (data.error) {
								// Sblocco la schermata
								blockUI.stop();
								toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
							} else {
								// Sblocco la schermata
								blockUI.stop();
								toaster.pop("success", $translate.instant('generic.OPERATION_PERFORMED'), $translate.instant('toolbar.toaster.LP_DELETED'));
								// Aggiorno lo $state
								$state.reload();
							}
						})
						.catch(function (error: any) {
							// Sblocco la schermata
							blockUI.stop();
							// Non mostro la modale di errore se ho cancellato volutamente la richiesta
							if (!error || error.config.timeout.$$state.status !== 1) {
								// Verifico se è un problema di connettività
								let errorMessage: string = null;

								// Nuovo oggetto d'errore
								let newError: any = {
									severity: "danger"
								}

								if (!error.data && error.status == -1 && (!error.statusText || !error.statusText.length) || error.status == 504 || error.status == 502) {
									// Problema di connettività
									errorMessage = this.$translate.instant("error.generic.NO_SERVER_TITLE");
									newError.hideUnknown = true;
								} else {
									// Messaggio di errore generico
									errorMessage = this.$translate.instant("error.generic.UNKNOWN_ERROR");
								}

								// Imposto il messaggio
								newError.message = errorMessage;

								// Lo aggiungo alla lista
								let errors: Array<any> = [];
								errors.push(newError);

								// E apro la modale
								this.$rootScope.$emit("showApplicationModalErrors", errors);
							}
						});
				}, function () {
					// In caso di dismiss non faccio niente
				});
		}

		// Controller per la modale che chiede la conferma per l'eliminazione dell'oggetto
		this.DeleteLearningPlanFromCardController = ($scope: any, $mdDialog: any) => {
			// Chiude la modale annullando l'operazione
			$scope.cancel = () => {
				$mdDialog.cancel();
			};

			// Confermo l'operazione e rimuovo l'oggetto
			$scope.confirm = () => {
				$mdDialog.hide();
			};
		}

		// Apre una modale che chiede conferma sulla rimozione del Learning Plan
		$scope.deleteProject = (event: any) => {
			$mdDialog.show({
				controller: this.DeleteProjectFromCardController,
				templateUrl: 'deleteProjectFromCard.html',
				parent: angular.element(document.body),
				targetEvent: event,
				clickOutsideToClose: true
			})
				.then(() => {
					// Blocco la schermata
					blockUI.start();

					// Rimuovo l'oggetto
					ProjectService.deleteProject.query({
						itemId: $scope.item.itemId,
						removeAtomicItemsToo: false
					}).$promise
						.then((data: SenecaResponse<Item>) => {
							// Se c'è segnalo l'errore
							if (data.error) {
								// Sblocco la schermata
								blockUI.stop();
								toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
							} else {
								// Sblocco la schermata
								blockUI.stop();
								toaster.pop("success", $translate.instant('generic.OPERATION_PERFORMED'), $translate.instant('editProject.PROJECT_DELETED'));
								// Aggiorno lo $state
								$state.reload();
							}
						})
						.catch(function (error: any) {
							// Sblocco la schermata
							blockUI.stop();
							// Non mostro la modale di errore se ho cancellato volutamente la richiesta
							if (!error || error.config.timeout.$$state.status !== 1) {
								// Verifico se è un problema di connettività
								let errorMessage: string = null;

								// Nuovo oggetto d'errore
								let newError: any = {
									severity: "danger"
								}

								if (!error.data && error.status == -1 && (!error.statusText || !error.statusText.length) || error.status == 504 || error.status == 502) {
									// Problema di connettività
									errorMessage = this.$translate.instant("error.generic.NO_SERVER_TITLE");
									newError.hideUnknown = true;
								} else {
									// Messaggio di errore generico
									errorMessage = this.$translate.instant("error.generic.UNKNOWN_ERROR");
								}

								// Imposto il messaggio
								newError.message = errorMessage;

								// Lo aggiungo alla lista
								let errors: Array<any> = [];
								errors.push(newError);

								// E apro la modale
								this.$rootScope.$emit("showApplicationModalErrors", errors);
							}
						});
				}, function () {
					// In caso di dismiss non faccio niente
				});
		}

		// Controller per la modale che chiede la conferma per l'eliminazione del progetto
		this.DeleteProjectFromCardController = ($scope: any, $mdDialog: any) => {
			// Chiude la modale annullando l'operazione
			$scope.cancel = () => {
				$mdDialog.cancel();
			};

			// Confermo l'operazione e rimuovo il progetto
			$scope.confirm = () => {
				$mdDialog.hide();
			};
		}

		// Apre una modale che chiede conferma sulla rimozione dell'item
		$scope.deleteAtomicItem = (event: any) => {
			$mdDialog.show({
				controller: this.DeleteItemFromCardController,
				templateUrl: 'deleteItemFromCard.html',
				targetEvent: event,
				parent: angular.element(document.body),
				clickOutsideToClose: true
			})
				.then(() => {
					// Blocco la schermata
					blockUI.start();

					// Rimuovo l'oggetto
					ItemDetailService.deleteItem.query({
						itemId: $scope.item.itemId,
						removeAtomicItemsToo: false
					}).$promise
						.then((data: SenecaResponse<Item>) => {
							// Se c'è segnalo l'errore
							if (data.error) {
								// Sblocco la schermata
								blockUI.stop();
								toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
							} else {
								// Sblocco la schermata
								blockUI.stop();
								toaster.pop("success", $translate.instant('generic.OPERATION_PERFORMED'), $translate.instant('editItem.DELETED'));
								// Aggiorno lo $state
								$state.reload();
							}
						})
						.catch((error: any) => {
							// Sblocco la schermata
							blockUI.stop();
							// Non mostro la modale di errore se ho cancellato volutamente la richiesta
							if (!error || error.config.timeout.$$state.status !== 1) {
								// Verifico se è un problema di connettività
								let errorMessage: string = null;

								// Nuovo oggetto d'errore
								let newError: any = {
									severity: "danger"
								}

								if (!error.data && error.status == -1 && (!error.statusText || !error.statusText.length) || error.status == 504 || error.status == 502) {
									// Problema di connettività
									errorMessage = this.$translate.instant("error.generic.NO_SERVER_TITLE");
									newError.hideUnknown = true;
								} else {
									// Messaggio di errore generico
									errorMessage = this.$translate.instant("error.generic.UNKNOWN_ERROR");
								}

								// Imposto il messaggio
								newError.message = errorMessage;

								// Lo aggiungo alla lista
								let errors: Array<any> = [];
								errors.push(newError);

								// E apro la modale
								this.$rootScope.$emit("showApplicationModalErrors", errors);
							}
						});
				}, () => {
					// In caso di dismiss non faccio niente
				});
		}

		// Controller per la modale che chiede la conferma per l'eliminazione dell'item
		this.DeleteItemFromCardController = ($scope: any, $mdDialog: any) => {
			// Chiude la modale annullando l'operazione
			$scope.cancel = () => {
				$mdDialog.cancel();
			};

			// Confermo l'operazione e rimuovo l'item
			$scope.confirm = () => {
				$mdDialog.hide();
			};
		}

		// Apre una modale che permette di invitare una persona o un gruppo a visualizzare l'oggetto
		$scope.inviteToUserOrGroup = (event: any) => {
			$mdDialog.show({
				controller: this.AddVisibilityToUserOrGroupController,
				templateUrl: 'addVisibilityToUserOrGroup.html',
				parent: angular.element(document.body),
				targetEvent: event,
				clickOutsideToClose: true,
				locals: {
					itemId: $scope.item.itemId,
					isProject: $scope.isProject,
					adminMode: $scope.adminMode
				}
			})
				.then(() => {
					// Già tutto gestito dal controller della modale
				}, () => {
					// In caso di dismiss non faccio niente
				});
		}

		// Controller per la modale che aggiunge la visibilità
		this.AddVisibilityToUserOrGroupController = ($scope: any, $mdDialog: any, $stateParams: any, itemId: string, isProject: any, adminMode?: boolean) => {
			$scope.adminMode = adminMode;

			// Id dell'oggetto
			$scope.itemId = itemId;

			$scope.forceMailAndNotification = true;

			// Verifica se è un progetto
			$scope.isProject = isProject;

			// Inizialmente il menu per scegliere a chi aggiungere la visbilità è aperto e visibile
			$scope.chooseToAddToMenuVisible = true;

			// Toggle menu aggiunta visibilità pubblica
			$scope.addToGroupMenuVisibile = false;

			// Utente selezionato a cui suggerire l'elemento
			$scope.selectedPerson = null;
			// Gruppo selezionato a cui suggerire l'elemento
			$scope.selectedGroup = null;
			// BlockUI della ricerca degli utenti
			$scope.searchBlockUI = blockUI.instances.get('searchBlockUI');

			// Dato che l'infinite scroll appende i dati, devo partire con una lista esistente vuota
			$scope.searchResult = [];

			// Testo della ricerca di un utente
			$scope.searchedUser = '';
			// Testo della ricerca di un gruppo
			$scope.searchedGroup = '';

			// Salvo il tipo di visibilità (aggiunta semplice o invito)
			$scope.visibilityType = null;

			// Variabili che bloccano l'infinite scroll finché sta caricando o la pagina non è pronta
			$scope.infiniteScrollEnabled = false;
			$scope.isInfiniteScrollLoading = false;
			$scope.noDataFound = false;

			// Count totale degli elementi trovati
			$scope.totalItemsCount = null;

			// Dato che l'infinite scroll appende i dati, devo partire con una lista esistente vuota
			$scope.infiniteScrollLoadCount = 12;
			$scope.infiniteScrollStartOffSet = 0;
			$scope.totalItemsLoaded = 0;

			// Eseguo la ricerca delle persone nel mio tenant, eliminando le eventuali richieste pendenti
			$scope.getPersonsInTenant = (newSearch?: boolean) => {
				$scope.isInfiniteScrollLoading = true;

				// Disabilito i pulsanti
				$scope.isDoingSomething = true;

				// Avvio lo loader
				$scope.searchBlockUI.start();

				// Se non ce l'ho già, recupero il count del totale degli elementi recuperati
				if (newSearch && $scope.searchedUser && $scope.searchedUser.length) {
					if (newSearch) {
						$scope.totalItemsCount = 0;
						$scope.searchResult.length = 0;
						$scope.totalItemsLoaded = 0;
						$scope.residualPersonsInTenantCount = 0;
						$scope.infiniteScrollStartOffSet = 0;
					}

					if ($scope.countItemPromise) {
						$scope.countItemPromise.$cancelRequest();
					}
					$scope.countItemPromise =
						ItemDetailService.countPersonsInTenant.query({
							searchedText: $scope.searchedUser
						});
					$scope.countItemPromise.$promise
						.then((data: SenecaResponse<number>) => {
							if (data.error) {
								// Dati non validi, quindi alzo l'errore
								toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
							} else {
								$scope.residualPersonsInTenantCount += data.response ? data.response : 0;
								// Torno i risultati della ricerca
								$scope.totalItemsCount = data.response;
							}
							// Annullo la promessa
							$scope.countItemPromise = null;
						})
						.catch((error: any) => {
							// Annullo la promessa
							$scope.countItemPromise = null;
							// Non mostro la modale di errore se ho cancellato volutamente la richiesta
							if (!error || error.config.timeout.$$state.status !== 1) {
								// Verifico se è un problema di connettività
								let errorMessage: string = null;

								// Nuovo oggetto d'errore
								let newError: any = {
									severity: "danger"
								}

								if (!error.data && error.status == -1 && (!error.statusText || !error.statusText.length) || error.status == 504 || error.status == 502) {
									// Problema di connettività
									errorMessage = this.$translate.instant("error.generic.NO_SERVER_TITLE");
									newError.hideUnknown = true;
								} else {
									// Messaggio di errore generico
									errorMessage = this.$translate.instant("error.generic.UNKNOWN_ERROR");
								}

								// Imposto il messaggio
								newError.message = errorMessage;

								// Lo aggiungo alla lista
								let errors: Array<any> = [];
								errors.push(newError);

								// E apro la modale
								this.$rootScope.$emit("showApplicationModalErrors", errors);
							}
						});
				}

				// Abilito la ricerca solo se è la prima volta o se mi mancano ancora altri dati da visualizzare
				if ((newSearch || $scope.infiniteScrollStartOffSet < $scope.totalItemsCount) && $scope.searchedUser && $scope.searchedUser.length) {
					if (newSearch) {
						$scope.searchResult.length = 0;
						$scope.totalItemsLoaded = 0;
						$scope.residualPersonsInTenantCount = 0;
						$scope.infiniteScrollStartOffSet = 0;
					}

					if ($scope.getPersonsInTenantPromise) {
						$scope.getPersonsInTenantPromise.$cancelRequest();
					}
					$scope.getPersonsInTenantPromise =
						ItemDetailService.getPersonsInTenant.query({
							searchedText: $scope.searchedUser,
							includeMe: true,
							fromRecord: $scope.infiniteScrollStartOffSet,
							numRecords: $scope.infiniteScrollLoadCount
						});
					$scope.getPersonsInTenantPromise.$promise
						.then((data: SenecaResponse<Array<User>>) => {
							if (data.error) {
								// Dati non validi, quindi alzo l'errore
								toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
							} else {
								$scope.residualPersonsInTenantCount -= data.response.length;
								// Aggiungo gli utenti trovato con la ricerca
								$scope.searchResult = $scope.searchResult.concat(data.response);
								$scope.totalItemsLoaded = $scope.searchResult.length;
								// Mi segno che sono arrivato a questo punto della lista
								$scope.infiniteScrollStartOffSet += data.response.length;
								if (!$scope.searchResult.length) {
									$scope.noDataFound = true;
								}

								// Visto che il rendering HTML in pagina potrebbe metterci un po', per evitare che l'infiniteScroll si invochi di nuovo
								// dato che la pagina non si è ancora riempita, il loading falso lo imposto dopo qualche millisencondo
								// Contemporaneamente abilito anche l'infinite scroll, che finora è stato spento perché la prima richiesta deve essere fatta manualmente
								$timeout(() => {
									$scope.infiniteScrollEnabled = true;
									$scope.isInfiniteScrollLoading = false;
								}, 500);
							}
							// Annullo la promessa
							$scope.getPersonsInTenantPromise = null;
							// E disattivo il loader
							$scope.searchBlockUI.stop();
							// Abilito i pulsanti
							$scope.isDoingSomething = false;
						})
						.catch((error: any) => {
							// Annullo la promessa
							$scope.getPersonsInTenantPromise = null;
							// Abilito i pulsanti
							$scope.isDoingSomething = false;
							// E disattivo il loader
							$scope.searchBlockUI.stop();
							// Non mostro la modale di errore se ho cancellato volutamente la richiesta
							if (!error || error.config.timeout.$$state.status !== 1) {
								// Verifico se è un problema di connettività
								let errorMessage: string = null;

								// Nuovo oggetto d'errore
								let newError: any = {
									severity: "danger"
								}

								if (!error.data && error.status == -1 && (!error.statusText || !error.statusText.length) || error.status == 504 || error.status == 502) {
									// Problema di connettività
									errorMessage = this.$translate.instant("error.generic.NO_SERVER_TITLE");
									newError.hideUnknown = true;
								} else {
									// Messaggio di errore generico
									errorMessage = this.$translate.instant("error.generic.UNKNOWN_ERROR");
								}

								// Imposto il messaggio
								newError.message = errorMessage;

								// Lo aggiungo alla lista
								let errors: Array<any> = [];
								errors.push(newError);

								// E apro la modale
								this.$rootScope.$emit("showApplicationModalErrors", errors);
							}
						});
				} else {
					// Abilito i pulsanti
					$scope.isDoingSomething = false;
					// Disattivo il blockUI
					$scope.searchBlockUI.stop();
				}
			}

			// Eseguo la ricerca dei gruppi
			$scope.getAllGroups = (newSearch?: boolean) => {
				$scope.isInfiniteScrollLoading = true;

				// Disabilito i pulsanti
				$scope.isDoingSomething = true;

				// Avvio lo loader
				$scope.searchBlockUI.start();

				// Se non ce l'ho già, recupero il count del totale degli elementi recuperati
				if (newSearch && $scope.searchedGroup && $scope.searchedGroup.length) {
					if (newSearch) {
						$scope.totalItemsCount = 0;
						$scope.searchResult.length = 0;
						$scope.totalItemsLoaded = 0;
						$scope.residualPersonsInTenantCount = 0;
						$scope.infiniteScrollStartOffSet = 0;
					}

					if ($scope.countItemPromise) {
						$scope.countItemPromise.$cancelRequest();
					}
					$scope.countItemPromise =
						GroupService.countTotalGroups.query({
							searchedText: $scope.searchedGroup
						});
					$scope.countItemPromise.$promise
						.then((data: SenecaResponse<number>) => {
							if (data.error) {
								// Dati non validi, quindi alzo l'errore
								toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
							} else {
								$scope.residualPersonsInTenantCount += data.response ? data.response : 0;
								// Torno i risultati della ricerca
								$scope.totalItemsCount = data.response;
							}
							// Annullo la promessa
							$scope.countItemPromise = null;
						})
						.catch((error: any) => {
							// Annullo la promessa
							$scope.countItemPromise = null;
							// Non mostro la modale di errore se ho cancellato volutamente la richiesta
							if (!error || error.config.timeout.$$state.status !== 1) {
								// Verifico se è un problema di connettività
								let errorMessage: string = null;

								// Nuovo oggetto d'errore
								let newError: any = {
									severity: "danger"
								}

								if (!error.data && error.status == -1 && (!error.statusText || !error.statusText.length) || error.status == 504 || error.status == 502) {
									// Problema di connettività
									errorMessage = this.$translate.instant("error.generic.NO_SERVER_TITLE");
									newError.hideUnknown = true;
								} else {
									// Messaggio di errore generico
									errorMessage = this.$translate.instant("error.generic.UNKNOWN_ERROR");
								}

								// Imposto il messaggio
								newError.message = errorMessage;

								// Lo aggiungo alla lista
								let errors: Array<any> = [];
								errors.push(newError);

								// E apro la modale
								this.$rootScope.$emit("showApplicationModalErrors", errors);
							}
						});
				}

				// Abilito la ricerca solo se è la prima volta o se mi mancano ancora altri dati da visualizzare
				if ((newSearch || $scope.infiniteScrollStartOffSet < $scope.totalItemsCount) && $scope.searchedGroup && $scope.searchedGroup.length) {
					if (newSearch) {
						$scope.searchResult.length = 0;
						$scope.totalItemsLoaded = 0;
						$scope.residualPersonsInTenantCount = 0;
						$scope.infiniteScrollStartOffSet = 0;
					}

					if ($scope.getPersonsInTenantPromise) {
						$scope.getPersonsInTenantPromise.$cancelRequest();
					}
					$scope.getPersonsInTenantPromise =
						GroupService.searchGroups.query({
							searchedText: $scope.searchedGroup,
							fromRecord: $scope.infiniteScrollStartOffSet,
							numRecords: $scope.infiniteScrollLoadCount
						});
					$scope.getPersonsInTenantPromise.$promise
						.then((data: SenecaResponse<Array<UserGroup>>) => {
							if (data.error) {
								// Dati non validi, quindi alzo l'errore
								toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
							} else {
								$scope.residualPersonsInTenantCount -= data.response.length;
								// Aggiungo gli utenti trovato con la ricerca
								$scope.searchResult = $scope.searchResult.concat(data.response);
								$scope.totalItemsLoaded = $scope.searchResult.length;
								// Mi segno che sono arrivato a questo punto della lista
								$scope.infiniteScrollStartOffSet += data.response.length;
								if (!$scope.searchResult.length) {
									$scope.noDataFound = true;
								}

								// Visto che il rendering HTML in pagina potrebbe metterci un po', per evitare che l'infiniteScroll si invochi di nuovo
								// dato che la pagina non si è ancora riempita, il loading falso lo imposto dopo qualche millisencondo
								// Contemporaneamente abilito anche l'infinite scroll, che finora è stato spento perché la prima richiesta deve essere fatta manualmente
								$timeout(() => {
									$scope.infiniteScrollEnabled = true;
									$scope.isInfiniteScrollLoading = false;
								}, 500);
							}
							// Annullo la promessa
							$scope.getPersonsInTenantPromise = null;
							// E disattivo il loader
							$scope.searchBlockUI.stop();
							// Abilito i pulsanti
							$scope.isDoingSomething = false;
						})
						.catch((error: any) => {
							// Annullo la promessa
							$scope.getPersonsInTenantPromise = null;
							// Abilito i pulsanti
							$scope.isDoingSomething = false;
							// E disattivo il loader
							$scope.searchBlockUI.stop();
							// Non mostro la modale di errore se ho cancellato volutamente la richiesta
							if (!error || error.config.timeout.$$state.status !== 1) {
								// Verifico se è un problema di connettività
								let errorMessage: string = null;

								// Nuovo oggetto d'errore
								let newError: any = {
									severity: "danger"
								}

								if (!error.data && error.status == -1 && (!error.statusText || !error.statusText.length) || error.status == 504 || error.status == 502) {
									// Problema di connettività
									errorMessage = this.$translate.instant("error.generic.NO_SERVER_TITLE");
									newError.hideUnknown = true;
								} else {
									// Messaggio di errore generico
									errorMessage = this.$translate.instant("error.generic.UNKNOWN_ERROR");
								}

								// Imposto il messaggio
								newError.message = errorMessage;

								// Lo aggiungo alla lista
								let errors: Array<any> = [];
								errors.push(newError);

								// E apro la modale
								this.$rootScope.$emit("showApplicationModalErrors", errors);
							}
						});
				} else {
					// Abilito i pulsanti
					$scope.isDoingSomething = false;
					// Disattivo il blockUI
					$scope.searchBlockUI.stop();
				}
			}

			// Salvo la persona a cui voglio suggerire l'elemento
			$scope.selectLearningPlan = (selectedLearningPlan: User) => {
				$scope.selectedLearningPlan = selectedLearningPlan;
			};

			// Porta alla lista dei gruppi
			$scope.showAllGroups = () => {
				// Pulisco i dati
				$scope.searchResult.length = 0;
				$scope.totalItemsLoaded = 0;
				$scope.residualPersonsInTenantCount = 0;
				$scope.infiniteScrollStartOffSet = 0;
				// Toggle dal menu alla lista dele persone
				$scope.chooseToAddToMenuVisible = false;
				$scope.addToGroupMenuVisibile = true;
				// ed eseguo la ricerca
				$scope.getAllGroups(true);
			}

			$scope.searchUser = () => {
				// Pulisco i dati
				$scope.searchResult.length = 0;
				$scope.totalItemsLoaded = 0;
				$scope.residualPersonsInTenantCount = 0;
				$scope.infiniteScrollStartOffSet = 0;
				// Toggle dal menu alla lista dele persone
				$scope.chooseToAddToMenuVisible = false;
				// ed eseguo la ricerca
				$scope.getPersonsInTenant(true);
			}

			// Chiede conferma per l'aggiunta della visibilità pubblica
			$scope.addPublicVisibility = () => {
				// Toggle dal menu principale al testo di conferma
				$scope.addToPublicMenuVisibile = true;
			}

			// Salvo la persona a cui voglio suggerire l'elemento
			$scope.selectPersonToSuggest = (selectedPerson: User, type: string) => {
				// Salvo il tipo di visibilità (aggiunta semplice o invito)
				$scope.visibilityType = type;
				$scope.selectedPerson = selectedPerson;
			};


			// Salvo il gruppo selezionato
			$scope.selectGroupToSuggest = (selectedGroup: User, type: string) => {
				// Salvo il tipo di visibilità (aggiunta semplice o invito)
				$scope.visibilityType = type;
				$scope.selectedGroup = selectedGroup;
			};

			// Torno alla ricerca degli utenti
			$scope.backToPersonList = () => {
				// Deseleziono l'utente
				$scope.selectedPerson = null;
			}

			// Torno alla ricerca dei gruppi
			$scope.backToGroupList = () => {
				// Deseleziono il gruppo
				$scope.selectedGroup = null;
			}

			// Torna al menu principale della modale
			$scope.backToMenu = () => {
				// Reset dei dati
				$scope.selectedPerson = null;
				$scope.selectedGroup = null;
				$scope.visibilityType = null;
				$scope.addToPublicMenuVisibile = false;
				$scope.addToGroupMenuVisibile = false;
				$scope.chooseToAddToMenuVisible = true;
			}

			// Chiude la modale annullando l'operazione
			$scope.cancel = () => {
				$mdDialog.cancel();
			};

			// Chiude la modale aggiungendo la visibilità all'utente o gruppo selezionato
			$scope.confirmAddVisibility = () => {
				// Verifico di aver selezionato un gruppo o un utente, affinché l'invio nella barra di ricerca non aggiunga a vuoto una visibilità
				if (!$scope.selectedPerson && !$scope.selectedGroup) {
					return;
				}

				// Blocco la schermata
				blockUI.start();

				let destination: any = null;
				if ($scope.selectedPerson) {
					destination = $scope.selectedPerson;
				} else if ($scope.selectedGroup) {
					destination = $scope.selectedGroup;
				}

				// Aggiungo la visibilità del progetto corrente all'utente o gru8ppo selezionato
				ItemDetailService.addVisibility.query({
					destination: destination,
					itemId: $scope.itemId,
					visibilityType: $scope.visibilityType == 'add' ? LibraryApplicationData.constants.VISIBILITY : LibraryApplicationData.constants.SUGGESTION,
					fromAdmin: $scope.visibilityType == 'add' ? false : true,
					ignoreVisibility: true,
					isProject: $scope.isProject,
					forceMailAndNotification: true
				}).$promise
					.then((data: SenecaResponse<Item>) => {
						// Se c'è segnalo l'errore
						if (data.error) {
							toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
						} else {
							toaster.pop("success", $translate.instant('generic.OPERATION_PERFORMED'), $translate.instant('generic.DATA_SAVED'));
						}
						// Sblocco la schermata
						blockUI.stop();
					})
					.catch(function (error: any) {
						// Sblocco la schermata
						blockUI.stop();
						// Non mostro la modale di errore se ho cancellato volutamente la richiesta
						if (!error || error.config.timeout.$$state.status !== 1) {
							// Verifico se è un problema di connettività
							let errorMessage: string = null;

							// Nuovo oggetto d'errore
							let newError: any = {
								severity: "danger"
							}

							if (!error.data && error.status == -1 && (!error.statusText || !error.statusText.length) || error.status == 504 || error.status == 502) {
								// Problema di connettività
								errorMessage = this.$translate.instant("error.generic.NO_SERVER_TITLE");
								newError.hideUnknown = true;
							} else {
								// Messaggio di errore generico
								errorMessage = this.$translate.instant("error.generic.UNKNOWN_ERROR");
							}

							// Imposto il messaggio
							newError.message = errorMessage;

							// Lo aggiungo alla lista
							let errors: Array<any> = [];
							errors.push(newError);

							// E apro la modale
							this.$rootScope.$emit("showApplicationModalErrors", errors);
						}
					});

				// Chiudo la modale
				$mdDialog.cancel();
			};
		}

		// Apre una modale che permette di cercare una persona condividere con lui un item
		$scope.suggestItem = (event: any) => {
			$mdDialog.show({
				controller: this.SuggestItemController,
				templateUrl: 'suggestItem.html',
				parent: angular.element(document.body),
				targetEvent: event,
				clickOutsideToClose: false,
				fullscreen: true,
				locals: {
					isItemPublic: $scope.isItemPublic,
					isLearningPlan: $scope.isLearningPlan,
					isMyItem: $scope.isMyItem
				}
			})
				.then((selectedUser: User) => {
					// Blocco la schermata
					blockUI.start();

					// Aggiungo la visibilità dell'oggetto corrente all'utente selezionato
					VisibilityService.addSuggestion.query({
						destinationUser: selectedUser,
						itemId: $scope.item.itemId
					}).$promise
						.then((data: SenecaResponse<Item>) => {
							// Se c'è segnalo l'errore
							if (data.error) {
								toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
							} else {
								toaster.pop("success", $translate.instant('generic.OPERATION_PERFORMED'), $translate.instant('toolbar.toaster.SUGGESTED'));
							}
							// Sblocco la schermata
							blockUI.stop();
						})
						.catch(function (error: any) {
							// Sblocco la schermata
							blockUI.stop();
							// Non mostro la modale di errore se ho cancellato volutamente la richiesta
							if (!error || error.config.timeout.$$state.status !== 1) {
								// Verifico se è un problema di connettività
								let errorMessage: string = null;

								// Nuovo oggetto d'errore
								let newError: any = {
									severity: "danger"
								}

								if (!error.data && error.status == -1 && (!error.statusText || !error.statusText.length) || error.status == 504 || error.status == 502) {
									// Problema di connettività
									errorMessage = this.$translate.instant("error.generic.NO_SERVER_TITLE");
									newError.hideUnknown = true;
								} else {
									// Messaggio di errore generico
									errorMessage = this.$translate.instant("error.generic.UNKNOWN_ERROR");
								}

								// Imposto il messaggio
								newError.message = errorMessage;

								// Lo aggiungo alla lista
								let errors: Array<any> = [];
								errors.push(newError);

								// E apro la modale
								this.$rootScope.$emit("showApplicationModalErrors", errors);
							}
						});
				}, function () {
					// In caso di dismiss non faccio niente
				});
		}

		// Controller per la modale del rifiuto del piano
		this.SuggestItemController = ($scope: any, $mdDialog: any, isItemPublic: boolean, isLearningPlan: boolean, isMyItem: boolean) => {
			// Verifica se l'oggetto possiede almeno una visibilità pubblica
			$scope.isItemPublic = isItemPublic;

			// Verifica se ho creato io l'oggetto
			$scope.isMyItem = isMyItem;

			// Verifica se sto suggerendo un learning plan
			$scope.isLearningPlan = isLearningPlan;

			// Utente selezionato a cui suggerire l'elemento
			$scope.selectedPerson = null;
			// BlockUI della ricerca degli utenti
			$scope.searchBlockUI = blockUI.instances.get('searchBlockUI');

			// Dato che l'infinite scroll appende i dati, devo partire con una lista esistente vuota
			$scope.searchResult = [];

			// Testo della ricerca
			$scope.searchedText = '';

			// Variabili che bloccano l'infinite scroll finché sta caricando o la pagina non è pronta
			$scope.infiniteScrollEnabled = false;
			$scope.isInfiniteScrollLoading = false;
			$scope.noDataFound = false;

			// Count totale degli elementi trovati
			$scope.totalItemsCount = null;

			// Dato che l'infinite scroll appende i dati, devo partire con una lista esistente vuota
			$scope.infiniteScrollLoadCount = 12;
			$scope.infiniteScrollStartOffSet = 0;
			$scope.totalItemsLoaded = 0;

			// Eseguo la ricerca delle persone nel mio tenant, eliminando le eventuali richieste pendenti
			$scope.getPersonsInTenant = (newSearch?: boolean) => {
				$scope.isInfiniteScrollLoading = true;

				// Disabilito i pulsanti
				$scope.isDoingSomething = true;

				// Avvio lo loader
				$scope.searchBlockUI.start();

				// Se non ce l'ho già, recupero il count del totale degli elementi recuperati
				if (newSearch && $scope.searchedText && $scope.searchedText.length) {
					if (newSearch) {
						$scope.totalItemsCount = 0;
						$scope.searchResult.length = 0;
						$scope.totalItemsLoaded = 0;
						$scope.residualPersonsInTenantCount = 0;
						$scope.infiniteScrollStartOffSet = 0;
					}

					if ($scope.countItemPromise) {
						$scope.countItemPromise.$cancelRequest();
					}
					$scope.countItemPromise =
						VisibilityService.countPersonsInTenant.query({
							searchedText: $scope.searchedText
						});
					$scope.countItemPromise.$promise
						.then((data: SenecaResponse<number>) => {
							if (data.error) {
								// Dati non validi, quindi alzo l'errore
								toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
							} else {
								$scope.residualPersonsInTenantCount += data.response ? data.response : 0;
								// Torno i risultati della ricerca
								$scope.totalItemsCount = data.response;
							}
							// Annullo la promessa
							$scope.countItemPromise = null;
						})
						.catch((error: any) => {
							// Annullo la promessa
							$scope.countItemPromise = null;
							// Non mostro la modale di errore se ho cancellato volutamente la richiesta
							if (!error || error.config.timeout.$$state.status !== 1) {
								// Verifico se è un problema di connettività
								let errorMessage: string = null;

								// Nuovo oggetto d'errore
								let newError: any = {
									severity: "danger"
								}

								if (!error.data && error.status == -1 && (!error.statusText || !error.statusText.length) || error.status == 504 || error.status == 502) {
									// Problema di connettività
									errorMessage = this.$translate.instant("error.generic.NO_SERVER_TITLE");
									newError.hideUnknown = true;
								} else {
									// Messaggio di errore generico
									errorMessage = this.$translate.instant("error.generic.UNKNOWN_ERROR");
								}

								// Imposto il messaggio
								newError.message = errorMessage;

								// Lo aggiungo alla lista
								let errors: Array<any> = [];
								errors.push(newError);

								// E apro la modale
								this.$rootScope.$emit("showApplicationModalErrors", errors);
							}
						});
				}

				// Abilito la ricerca solo se è la prima volta o se mi mancano ancora altri dati da visualizzare
				if ((newSearch || $scope.infiniteScrollStartOffSet < $scope.totalItemsCount) && $scope.searchedText && $scope.searchedText.length) {

					if (newSearch) {
						$scope.searchResult.length = 0;
						$scope.totalItemsLoaded = 0;
						$scope.residualPersonsInTenantCount = 0;
						$scope.infiniteScrollStartOffSet = 0;
					}

					if ($scope.getPersonsInTenantPromise) {
						$scope.getPersonsInTenantPromise.$cancelRequest();
					}
					$scope.getPersonsInTenantPromise =
						VisibilityService.getPersonsInTenant.query({
							searchedText: $scope.searchedText,
							fromRecord: $scope.infiniteScrollStartOffSet,
							numRecords: $scope.infiniteScrollLoadCount
						});
					$scope.getPersonsInTenantPromise.$promise
						.then((data: SenecaResponse<Array<User>>) => {
							if (data.error) {
								// Dati non validi, quindi alzo l'errore
								toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
							} else {
								$scope.residualPersonsInTenantCount -= data.response.length;
								// Aggiungo gli utenti trovato con la ricerca
								$scope.searchResult = $scope.searchResult.concat(data.response);
								$scope.totalItemsLoaded = $scope.searchResult.length;
								// Mi segno che sono arrivato a questo punto della lista
								$scope.infiniteScrollStartOffSet += data.response.length;
								if (!$scope.searchResult.length) {
									$scope.noDataFound = true;
								}

								// Visto che il rendering HTML in pagina potrebbe metterci un po', per evitare che l'infiniteScroll si invochi di nuovo
								// dato che la pagina non si è ancora riempita, il loading falso lo imposto dopo qualche millisencondo
								// Contemporaneamente abilito anche l'infinite scroll, che finora è stato spento perché la prima richiesta deve essere fatta manualmente
								$timeout(() => {
									$scope.infiniteScrollEnabled = true;
									$scope.isInfiniteScrollLoading = false;
								}, 500);
							}
							// Annullo la promessa
							$scope.getPersonsInTenantPromise = null;
							// E disattivo il loader
							$scope.searchBlockUI.stop();
							// Abilito i pulsanti
							$scope.isDoingSomething = false;
						})
						.catch((error: any) => {
							// Annullo la promessa
							$scope.getPersonsInTenantPromise = null;
							// Abilito i pulsanti
							$scope.isDoingSomething = false;
							// E disattivo il loader
							$scope.searchBlockUI.stop();
							// Non mostro la modale di errore se ho cancellato volutamente la richiesta
							if (!error || error.config.timeout.$$state.status !== 1) {
								// Verifico se è un problema di connettività
								let errorMessage: string = null;

								// Nuovo oggetto d'errore
								let newError: any = {
									severity: "danger"
								}

								if (!error.data && error.status == -1 && (!error.statusText || !error.statusText.length) || error.status == 504 || error.status == 502) {
									// Problema di connettività
									errorMessage = this.$translate.instant("error.generic.NO_SERVER_TITLE");
									newError.hideUnknown = true;
								} else {
									// Messaggio di errore generico
									errorMessage = this.$translate.instant("error.generic.UNKNOWN_ERROR");
								}

								// Imposto il messaggio
								newError.message = errorMessage;

								// Lo aggiungo alla lista
								let errors: Array<any> = [];
								errors.push(newError);

								// E apro la modale
								this.$rootScope.$emit("showApplicationModalErrors", errors);
							}
						});
				} else {
					// Abilito i pulsanti
					$scope.isDoingSomething = false;
					// Disattivo il blockUI
					$scope.searchBlockUI.stop();
				}
			}

			// Salvo la persona a cui voglio suggerire l'elemento
			$scope.selectPersonToSuggest = (selectedPerson: User) => {
				$scope.selectedPerson = selectedPerson;
			};

			// Torno alla ricerca degli utenti
			$scope.backToList = () => {
				// Deseleziono l'utente
				$scope.selectedPerson = null;
			}

			// Chiude la modale annullando l'operazione
			$scope.cancel = () => {
				$mdDialog.cancel();
			};

			// Confermo l'operazione e chiudo il piano
			$scope.confirm = () => {
				// Per prevenire l'azione all'invio nella barra di ricerca, mi assicuro di avere un utente selezionato
				if (!$scope.selectedPerson) {
					return;
				}
				$mdDialog.hide($scope.selectedPerson);
			};
		}
	}
});